import React, {useEffect, useRef, useState} from 'react';
import {
  Button, Card, Col, Descriptions, Form, Input, message, Modal, Radio, Row, Select, Spin, Tooltip, Upload,
} from 'antd';
import SignatureCanvas from 'react-signature-canvas';
import {InfoCircleOutlined} from '@ant-design/icons';
import {request} from '@/utils';
import './index.scss';
import './ConfirmationPage.css';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import {useLocation, useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import Notice from '@/pages/Usersign/UsersignSecondPage/Notice';

const {Option} = Select;

const UserForm = () => {
  const [issended, setIssended] = useState(false);
  const signBoxRef = useRef(null);
  const [signBoxWidth, setSignBoxWidth] = useState(0);
  const [email, setEmail] = useState(null);
  const [idPhotoBase64, setIdPhotoBase64] = useState(null);
  const [selfIdentImg, setSelfIdentImg] = useState(null);
  const [selfIdentImgSec, setSelfIdentImgSec] = useState(null);
  const [bankAccountImageBase64, setBankAccountImageBase64] = useState(null);
  const [familyMemberResidenceCertificateImageBase64, setFamilyMemberResidenceCertificateImageBase64] = useState(null);
  const [identificationDocumentImageBase64, setIdentificationDocumentImageBase64] = useState(null);
  const [japaneseSurname, setJapaneseSurname] = useState(false);
  const [idUploadState, setIdUploadState] = useState(false);
  const [bankUploadState, setBankUploadState] = useState(false);
  const [formSign] = Form.useForm();
  const signatureRef = useRef();

  let payload;
  let token = null;

  // 清除签名
  const handleClearSignature = () => {
    signatureRef.current.clear();
  };

  const navigate = useNavigate(); // 初始化useNavigate
  const location = useLocation(); // 定义 location 变量

  useEffect(() => {
    // 获取 URL 中的哈希部分
    const hashString = window.location.hash;
    let token = '';
    if (hashString && hashString.includes('token=')) {
      // 分割哈希部分字符串，以 token= 为分隔符，获取键值对数组
      const tokenArray = hashString.split('token=');
      // 获取数组的第二个元素，即 token 值
      token = tokenArray[1];
    }

    async function getList() {
      try {
        const res = await request.get(`/submitPageDetailedByToken?token=${token}`);
        setEmail(res.data);
        if (res.status === 'notSubmitted') {
          message.success('内容を順番に入力してください');
        } else if (res.status === 'submitted') {
          message.error('情報の提出が成功しました。再提出はご遠慮ください。');
          setIssended(true);
          navigate('/submittedPage'); // 跳转到已提交的页面
        } else if (res.status === 'invalid') {
          navigate('/invalidPage'); // 跳转到签约网址已失效或过期页面
        } else {
          navigate('/notFoundPage'); // 其他情况跳转到404页面
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data.status === 'invalid') {
            navigate('/invalidPage');
          } else {
            navigate('/notFoundPage');
            message.error('Bad Request: ' + error.response.data.message);
          }
        } else {
          navigate('/notFoundPage');
          message.error('An unexpected error occurred');
        }
      }
    }

    getList();

    const width = signBoxRef.current.clientWidth;
    setSignBoxWidth(width);
    // 从 sessionStorage 中读取表单数据并填充表单
    const storedFormData = sessionStorage.getItem('formData');
    if (storedFormData) {
      formSign.setFieldsValue(JSON.parse(storedFormData));
      sessionStorage.removeItem('formData'); // 清除 sessionStorage 中的数据
    }
  }, [navigate]); // 依赖列表中的navigate保证了navigate变化时重新发起请求

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);

  const onFinish = async (values) => {
    if (issended) {
      message.error('再送信しないでください');
    } else {
      const signatureData = signatureRef.current.toDataURL(); // 将签名保存为DataURL
      // setUserSignImageBase64(signatureData);
      // 获取token
      const hashString = window.location.hash;
      if (hashString && hashString.includes('token=')) {
        // 分割哈希部分字符串，以 token= 为分隔符，获取键值对数组
        const tokenArray = hashString.split('token=');
        // 获取数组的第二个元素，即 token 值
        token = tokenArray[1];
      }
      console.log(values);
      console.log(token);
      values.userEmail = email;
      values.financialInstitutionName = bankName;
      values.branchName = branchName;

      if (!values.japaneseCommonSurname) {
        values.japaneseCommonSurname = null;

      }
      if (!values.japaneseCommonGivenName) {

        values.japaneseCommonGivenName = null;
      }

      delete values.selfIdentificationDocumentImage;
      delete values.selfIdentificationDocumentImageSec;
      delete values.bankDocumentImageSec;
      delete values.familyselfIdentificationDocumentImageSec;
      delete values.japNameselfIdentificationDocumentImageSec;
      delete values.country;
      delete values.imgtype;
      delete values.branchCode;

      payload = {
        userData: values,
        idPhotoBase64: idPhotoBase64,
        selfIdentificationDocumentImageBase64: selfIdentImg,
        selfIdentificationDocumentImage2Base64: selfIdentImgSec,
        bankAccountImageBase64: bankAccountImageBase64,
        familyMemberResidenceCertificateImageBase64: familyMemberResidenceCertificateImageBase64,
        identificationDocumentImageBase64: identificationDocumentImageBase64,
        userSignImageBase64: signatureData,
        token: token,
      };
      payload.userData.address = address;
      payload.userData.dateOfBirth = dateOfBirth;
      payload.userData.idPhoto = null;
      payload.userData.selfIdentificationDocumentImage = null;
      payload.userData.selfIdentificationDocumentImage2 = null;
      payload.userData.bankAccountImage = null;
      payload.userData.familyMemberResidenceCertificateImage = null;
      payload.userData.identificationDocumentImage = null;
      payload.userData.userSignImage = null;

      setModalData(payload);
      setIsModalVisible(true);
    }

  };

  //Modal提交表单的确认界面↓
  const handleEdit = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    try {
      const res = await request.post('/submit', modalData);
      if (res === '签约申请保存成功') {
        message.success('提出しました！ありがとうございました。');
        setIssended(true);
        setIsModalVisible(false);
        navigate('/submittedPage');
      } else {
        message.error(res);
      }
    } catch (error) {
      message.error('An unexpected error occurred');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fieldLabels = {
    gender: '性别',
    country: '国籍',
    englishSurname: 'ローマ字姓',
    englishGivenName: 'ローマ字名',
    chineseSurname: '本国姓',
    chineseGivenName: '本国名',
    japaneseCommonSurname: '日本姓',
    japaneseCommonGivenName: '日本名',
    furiganaSurname: 'フリガナ姓',
    furiganaGivenName: 'フリガナ名',
    phoneNumber: '電話番号',
    postalCode: '郵便番号',
    address: '住所',
    emergencyContactName: '緊急連絡先名',
    emergencyContactPhoneNumber: '緊急連絡先電話',
    emergencyContactRelationship: '緊急連絡先関係',
    accountHolderName: '口座名義',
    financialInstitutionName: '金融機関',
    branchCode: '支店コード',
    branchName: '支店名',
    accountNumber: '口座番号',
    userEmail: 'メールアドレス',
    identificationDocumentImage: '全ての画像は表示されません',
    selfIdentificationDocumentImage: '身分証明書（表）',
    selfIdentificationDocumentImage2: '身分証明書（裏）',
    bankAccountImage: '銀行口座写真',
    familyMemberResidenceCertificateImage: '情報セキュリティ守るために',
    userSignImage: 'ご安心ください',
    dateOfBirth: '生年月日',
    idPhoto: '顔写真',// Add more fields as necessary
  };

  const genderMapping = {
    male: '男', female: '女',
  };
  //Modal提交表单的确认界面↑

  // 通用文件上传处理函数↓
  const [loading, setLoading] = useState(false); // 添加loading状态

  const handleImageUpload = (e, setImageBase64, imageType) => {
    if (!Array.isArray(e) && e.fileList[0]) {
      const file = e.file;
      const reader = new FileReader();

      reader.onload = async () => {
        const base64String = reader.result;
        setImageBase64(base64String);
        setLoading(true); // 开始上传，显示加载提示

        try {
          // 上传图片到后端
          const response = await fetch('https://sign.jtmh.jp/api/upload', {
            method: 'POST', headers: {
              'Content-Type': 'application/json',
            }, body: JSON.stringify({base64Image: base64String, imageType}), // 传递图片和类型
          });

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const data = await response.json();

          if (data.message === 200) {
            message.success(data.data);
          } else {
            message.error(data.data);
          }
        } catch (error) {
          message.error(`Upload failed: ${error.message}`);
        } finally {
          setLoading(false); // 上传结束，隐藏加载提示
        }
      };

      reader.readAsDataURL(file);
    } else {
      setImageBase64(null);
    }

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const idPhoto = (e) => {
    console.log('上传证件照图片:', e);
    return handleImageUpload(e, setIdPhotoBase64, 'idPhoto');
  };

  const idImgFirst = (type) => (e) => {
    console.log('上传第一张身份证图片:', e);
    if (type === 'zaiLiuKa1') {
      return handleImageUpload(e, setSelfIdentImg, 'idFirstZaiLiuKa');
    } else if (type === 'shuLei1') {
      return handleImageUpload(e, setSelfIdentImg, 'idFirstShuLei');
    }
  };

  const idImgSec = (type) => (e) => {
    console.log('上传第二张身份证图片:', e);
    if (type === 'zaiLiuKa2') {
      return handleImageUpload(e, setSelfIdentImgSec, 'idSecZaiLiuKa');
    } else if (type === 'shuLei2') {
      return handleImageUpload(e, setSelfIdentImgSec, 'idSecShuLei');
    }
  };

  const bankImgSec = (e) => {
    console.log('上传银行图片:', e);
    return handleImageUpload(e, setBankAccountImageBase64, 'bank');
  };

  const familyImgSec = (e) => {
    console.log('上传家庭成员户籍证明图片:', e);
    return handleImageUpload(e, setFamilyMemberResidenceCertificateImageBase64, 'family');
  };

  const japNameImgSec = (e) => {
    console.log('上传日本名字图片:', e);
    return handleImageUpload(e, setIdentificationDocumentImageBase64, 'japName');
  };
// 通用文件上传处理函数↑

  const handleBankChange = (value) => {
    console.log(`selected ${value}`);
    setBankUploadState(value === '家人名義');
  };
  const handleJapaneseSurnameChange = (e) => {
    const value = e.target.value;
    // 如果value不为空，则将状态设置为true
    if (value.trim() !== '') {
      console.log(value.trim() !== '');
      // 如果输入的值是空格或者空字符串则不设置为true
      setJapaneseSurname(true);
    } else {
      console.log(value.trim() !== '');
      setJapaneseSurname(false);
    }
  };
  const onChangeCountry = (e) => {
    console.log('是否为日本国籍:', e.target.value);
    setIdUploadState(e.target.value === 'japanese');
  };
  //银行处理↓
  const [bankName, setBankName] = useState('');
  const [suggestedBanks, setSuggestedBanks] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isSuggestedOpen, setIsSuggestedOpen] = useState(false);
  const [bankCode, setBankCode] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [branchName, setBranchName] = useState('');
  const [allBanks, setAllBanks] = useState([]);

  useEffect(() => {
    const fetchAllBanks = async () => {
      let fetchedBanks = [];
      let currentPage = 1;

      while (currentPage <= 24) {
        const api = `https://bank.teraren.com/banks.json?page=${currentPage}`;
        const response = await fetch(api);
        const data = await response.json();

        if (data && data.length > 0) {
          fetchedBanks = fetchedBanks.concat(data);
          currentPage += 1;
        } else {
          break;
        }
      }
      setAllBanks(fetchedBanks);
    };
    fetchAllBanks();
  }, []);

  const handleBankNameInputChange = async (e) => {
    setIsSuggestedOpen(true);
    const value = e.target.value;
    setBankName(value);

    if (value) {
      const filteredData = allBanks.filter((bank) => bank.normalize.name.includes(value) || bank.normalize.hira.includes(value) || bank.normalize.kana.includes(value));
      setSuggestedBanks(filteredData);
    } else {
      setSuggestedBanks([]);
      setBranchCode('');
      setBranchName('');
      setIsSuggestedOpen(false);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  const bankNameMessage = () => {
    if (!isFocused && bankName.length === 0) return null;
    if (bankName.length === 0 || suggestedBanks.length === 0) {
      return <li className="error-text">該当の銀行は見つかりません</li>;
    }
    return suggestedBanks.map((bank) => (<li
      key={bank.code}
      className="bank-name"
      onClick={() => handleBankNameClick(bank.normalize.name, bank.code)}
    >
      {bank.normalize.name}
    </li>));
  };

  const handleBankNameClick = (name, code) => {
    setBankName(name);
    setBankCode(code);
    setSuggestedBanks([]);
    setIsSuggestedOpen(false);
  };

  const fetchAllBranches = async (bankCode) => {
    let allBranches = [];
    let currentPage = 1;
    let hasMore = true;

    while (hasMore) {
      const api = `https://bank.teraren.com/banks/${bankCode}/branches.json?page=${currentPage}`;
      const response = await fetch(api);
      const data = await response.json();

      if (data && data.length > 0) {
        allBranches = allBranches.concat(data);
        currentPage++;
      } else {
        hasMore = false;
      }
    }
    return allBranches;
  };

  const handleBranchNameChange = async (e) => {
    const value = e.target.value;
    setBranchName(value);

    if (bankCode.length === 4 && value.length) {
      const branches = await fetchAllBranches(bankCode);
      const branch = branches.find((branch) => branch.name.includes(value));
      if (branch) {
        setBranchCode(branch.code);
      } else {
        setBranchCode('');
      }
    } else {
      setBranchCode('');
    }
  };

  const handleBranchCodeChange = async (e) => {
    const value = e.target.value;
    setBranchCode(value);

    if (bankCode.length === 4 && value.length === 3) {
      fetch(`https://bank.teraren.com/banks/${bankCode}/branches/${value}.json`)
        .then((response) => response.json())
        .then((json) => {
          setBranchName(json.name);
        })
        .catch((error) => {
          setBranchName('');
        });
    } else {
      setBranchName('');
    }
  };
  //银行处理↑

  //邮编地址处理↓
  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState('');

  // 处理邮编输入变化
  const handlePostalCodeChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setPostalCode(value);

    // 检查邮编是否符合7位数字的格式
    if (/^[0-9]{7}$/.test(value)) {
      fetchAddress(value);
    } else {
      setAddressBase(''); // 如果邮编格式不正确，清空地址字段
    }
  };

  // 发送请求获取地址
  const fetchAddress = async (postalCode) => {
    try {
      const response = await request.get(`/address?postalCode=${postalCode}`);
      if (response.statusCode === 200) {
        setAddressBase(response.data);
      } else {
        setAddressBase(''); // 如果返回状态码不为200，清空地址字段
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      setAddressBase(''); // 请求失败时，清空地址字段
    }
  };

  const [addressBase, setAddressBase] = useState(''); // 用于存储基础地址
  const [specificAddress, setSpecificAddress] = useState('');

  // 当 addressBase 或 specificAddress 更改时，使用 useEffect 更新 address
  useEffect(() => {
    setAddress(`${addressBase} ${specificAddress}`.trim());
  }, [addressBase, specificAddress]);
  //邮编地址处理↑

  // 每次 address 更新时更新表单值
  useEffect(() => {
    formSign.setFieldsValue({addressBase});
  }, [addressBase]);

  useEffect(() => {
    formSign.setFieldsValue({financialInstitutionName: bankName});
  }, [bankName]);

  useEffect(() => {
    formSign.setFieldsValue({branchName});
  }, [branchName]);

  useEffect(() => {
    formSign.setFieldsValue({branchCode});
  }, [branchCode]);

  //罗马姓名输入是否英文检测↓
  const [englishSurname, setEnglishSurname] = useState('');
  const [englishGivenName, setEnglishGivenName] = useState('');

  const englishValidateAndSet = (e, setValue) => {
    const value = e.target.value;
    const newValue = value.replace(/[^A-Z]/g, '').toUpperCase();
    if (newValue !== value.toUpperCase()) {
      message.error('英字大文字を入力してください');
    }
    setValue(newValue);
  };
  //罗马姓名输入是否英文检测↑

  //紧急联系人禁止输入数字和符号↓
  const [emergencyContactName, setEmergencyContactName] = useState('');

  const validateEmergencyContactName = (e) => {
    const value = e.target.value;
    // 允许任何字符，除了全角和半角的数字和符号
    const newValue = value.replace(/[\d\p{P}\p{S}\p{N}\p{Z}]/gu, '');
    if (newValue !== value) {
      message.error('数字や記号を入力しないでください');
    }
    setEmergencyContactName(newValue);
  };
  //紧急联系人禁止输入数字和符号↑

  //フリガナ姓名输入是否フリガナ检测↓
  const [furiganaSurname, setFuriganaSurname] = useState('');
  const [furiganaGivenName, setFuriganaGivenName] = useState('');

  const furiganaValidate = (_, value) => {
    if (value && !/^[\u30A0-\u30FF]+$/.test(value)) {
      return Promise.reject(new Error('フリガナ(カタカナ)を入力してください'));
    }
    return Promise.resolve();
  };
  //フリガナ姓名输入是否フリガナ检测↑

  //电话号码
  const [phoneNumber, setPhoneNumber] = useState('');

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    // 触发表单重新验证紧急联系电话
    formSign.validateFields(['emergencyContactPhoneNumber']);
  };

  const [emergencyContactPhoneNumber, setEmergencyContactPhoneNumber] = useState('');

  const handleEmergencyContactPhoneNumberChange = (value) => {
    setEmergencyContactPhoneNumber(value);
    // 触发表单重新验证电话号码
    formSign.validateFields(['phoneNumber']);
  };

  //电话号码不能重复判断
  const checkPhoneNumbers = (fieldName, value) => {
    if (fieldName === 'phoneNumber' && value === emergencyContactPhoneNumber) {
      return Promise.reject(new Error('緊急連絡電話番号はガイド契約電話番号と同じではありません'));
    } else if (fieldName === 'emergencyContactPhoneNumber' && value === phoneNumber) {
      return Promise.reject(new Error('緊急連絡電話番号はガイド契約電話番号と同じではありません'));
    }
    return Promise.resolve();
  };

  //生日选择器
  const years = [];
  const months = [];
  const days = [];

  for (let i = 1900; i <= new Date().getFullYear(); i++) {
    years.push(<Option key={i} value={i}>{i}</Option>);
  }

  for (let i = 1; i <= 12; i++) {
    const month = String(i).padStart(2, '0');
    months.push(<Option key={month} value={month}>{month}</Option>);
  }

  for (let i = 1; i <= 31; i++) {
    const day = String(i).padStart(2, '0');
    days.push(<Option key={day} value={day}>{day}</Option>);
  }

  const [birthYear, setBirthYear] = useState(null);
  const [birthMonth, setBirthMonth] = useState(null);
  const [birthDay, setBirthDay] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState('');

  // 当 birthYear 或 birthMonth 或 birthDay 更改时，使用 useEffect 更新 dateOfBirth
  useEffect(() => {
    setDateOfBirth(`${birthYear}-${birthMonth}-${birthDay}`.trim());
  }, [birthYear, birthMonth, birthDay]);

  //画面
  return (<div className="form_body">
      <Helmet>
        <title>JTMH-契約ページ-ガイドシステム</title>
      </Helmet>
      <h3>JTMHガイド契約ページ</h3>
      {/* <label>上から順に記入してください</label> */}
      <Form name="user-form" onFinish={onFinish} form={formSign}>
        <div className="form-item">
          <Form.Item
            name="gender"
            label={<span><span className="required">*</span>性别</span>}
            rules={[{
              required: true, message: '選択してください',
            }]}
          >
            <Radio.Group>
              <Radio value="male">男</Radio>
              <Radio value="female">女</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <div className="form-item">
          <Form.Item
            name="country"
            label={<span><span className="required">*</span>国籍</span>}
            rules={[{
              required: true, message: '選択してください',
            }]}
          >
            <Radio.Group onChange={onChangeCountry}>
              <Radio value="japanese">日本</Radio>
              <Radio value="nojapanese">日本以外</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <div className="form-item">
          <Form.Item style={{marginBottom: 0}}>
            <label>
              <span className="required">*</span>ローマ字(半角)
              <p style={{fontSize: '14px'}}>※パスポートに記載のローマ字を記入してください</p>
              <div className="inline-inputs">
                <Form.Item
                  name="englishSurname"
                  style={{display: 'inline-block', width: 'calc(50% - 8px)'}}
                  rules={[{required: true, message: '英字大文字を入力してください'}]}
                >
                  <div>
                    <span className="input-label" style={{marginRight: '8px', color: '#555'}}>Last Name·姓</span>
                    <Input
                      className="inputBox"
                      placeholder="ZHANG"
                      value={englishSurname}
                      onChange={(e) => englishValidateAndSet(e, setEnglishSurname)}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  name="englishGivenName"
                  rules={[{required: true, message: '英字大文字を入力してください'}]}
                  style={{display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px'}}
                >
                  <div>
                    <span className="input-label" style={{marginRight: '8px', color: '#555'}}>First Name·名</span>
                    <Input
                      className="inputBox"
                      placeholder="WEI"
                      value={englishGivenName}
                      onChange={(e) => englishValidateAndSet(e, setEnglishGivenName)}
                    />
                  </div>
                </Form.Item>
              </div>
            </label>
          </Form.Item>
        </div>

        {!idUploadState && (<div className="form-item">
          <Form.Item style={{marginBottom: 0}}>
            <label><span className="required">*</span>本国姓名(全角)
              <div className="inline-inputs">
                <Form.Item
                  rules={[{required: true, message: '入力してください'}]}
                  name="chineseSurname"
                  style={{display: 'inline-block', width: 'calc(50% - 8px)'}}
                >
                  <div>
                    <span className="input-label" style={{marginRight: '8px', color: '#555'}}>姓</span>
                    <Input className="inputBox" placeholder="张" />
                  </div>
                </Form.Item>
                <Form.Item
                  rules={[{required: true, message: '入力してください'}]}
                  name="chineseGivenName"
                  style={{display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px'}}
                >
                  <div>
                    <span className="input-label" style={{marginRight: '8px', color: '#555'}}>名</span>
                    <Input className="inputBox" placeholder="伟" />
                  </div>
                </Form.Item>
              </div>
            </label>
          </Form.Item>
        </div>)}

        <div className="form-item">
          <Form.Item style={{marginBottom: 0}}>
            <label>{idUploadState && <span className="required">*</span>}日本姓名/日本語通称姓名(全角)<Tooltip
              title={<>
                <p>日本語通称姓名とは、実社会において、本名と同様に、あるいは本名以上に通用している名前です。<br />
                  その人が日常生活や職業上の理由から実際に使っている名前であって、戸籍に記載される氏名とは異なります。<br />以下の証明書に記載されています：
                </p>
                <img src="https://sign.jtmh.jp/PDFs/license1.png" alt="運転免許証表"
                     style={{width: '285px'}} />
                <img src="https://sign.jtmh.jp/PDFs/license2.png" alt="運転免許証裏"
                     style={{width: '285px'}} />
                <img src="https://sign.jtmh.jp/PDFs/myNumber.png" alt="マイナンバーカード"
                     style={{width: '285px'}} />
                {/*<img src="path/to/residence_card_image.jpg" alt="住民票" style={{width: '333px'}} />*/}
              </>}
              placement="right"
              overlayInnerStyle={{width: '300px'}}>
              <InfoCircleOutlined style={{fontSize: '16px', marginLeft: '5px'}} />
            </Tooltip>
              <p style={{fontSize: '14px'}}>(外国籍の方は日本語通称名ない場合は、空白にしてください)</p>
              <div className="inline-inputs">
                <Form.Item
                  rules={idUploadState ? [{required: true, message: '入力してください'}] : []}
                  name="japaneseCommonSurname"
                  style={{display: 'inline-block', width: 'calc(50% - 8px)'}}
                >
                  <div>
                    <span className="input-label" style={{marginRight: '8px', color: '#555'}}>姓</span>
                    <Input className="inputBox" placeholder="ガイド"
                           onChange={handleJapaneseSurnameChange} />
                  </div>
                </Form.Item>
                <Form.Item
                  rules={idUploadState ? [{required: true, message: '入力してください'}] : []}
                  name="japaneseCommonGivenName"
                  style={{display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px'}}
                >
                  <div>
                    <span className="input-label" style={{marginRight: '8px', color: '#555'}}>名</span>
                    <Input className="inputBox" placeholder="太郎" />
                  </div>
                </Form.Item>
              </div>
            </label>
          </Form.Item>
        </div>

        <div className="form-item">
          <Form.Item style={{marginBottom: 0}}>
            <label><span className="required">*</span>フリガナ(全角)
              <p
                style={{fontSize: '14px'}}>(日本姓名のカタカナを記入してください。日本姓名がない場合は、本国姓名のカタカナを記入してください)</p>
              <div className="inline-inputs">
                <Form.Item
                  rules={[{required: true, message: 'カタカナを入力してください'}, {validator: furiganaValidate}]}
                  name="furiganaSurname"
                  style={{display: 'inline-block', width: 'calc(50% - 8px)'}}
                >
                  <div>
                    <span className="input-label" style={{marginRight: '8px', color: '#555'}}>セイ</span>
                    <Input
                      className="inputBox"
                      placeholder="チョウ"
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  rules={[{required: true, message: 'カタカナを入力してください'}, {validator: furiganaValidate}]}
                  name="furiganaGivenName"
                  style={{display: 'inline-block', width: 'calc(50% - 8px)'}}
                >
                  <div>
                    <span className="input-label" style={{marginRight: '8px', color: '#555'}}>メイ</span>
                    <Input
                      className="inputBox"
                      placeholder="イ"
                    />
                  </div>
                </Form.Item>
              </div>
            </label>
          </Form.Item>
        </div>

        <div className="form-item">
          <Form.Item name="phoneNumber" validateTrigger="onBlur" rules={[{
            required: true, message: '地域を選択してから電話を入力してください',
          }, {
            pattern: new RegExp(/^(0\d{2}-\d{4}-\d{4})$/), message: '有効な電話番号を入力してください',
          }, {
            validator: () => checkPhoneNumbers('emergencyContactPhoneNumber', emergencyContactPhoneNumber),
          }]}>
            <label><span className="required">*</span>電話番号
              <PhoneInput
                defaultCountry="JP"
                placeholder="地域を選択してから電話を入力してください"
                className="custom-phone-input"
                maxLength={13}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </label>
          </Form.Item>
        </div>

        <div className="form-item">
          <Form.Item style={{marginBottom: 0}}>
            <label><span className="required">*</span>生年月日


              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item
                    rules={[{required: true, message: '年を選択してください'}]}
                    noStyle
                  >
                    <Select
                      placeholder="年を選択"
                      className="inputBox"
                      value={birthYear}
                      onChange={setBirthYear}
                    >
                      {years}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    rules={[{required: true, message: '月を選択してください'}]}
                    noStyle
                  >
                    <Select
                      placeholder="月を選択"
                      className="inputBox"
                      value={birthMonth}
                      onChange={setBirthMonth}
                    >
                      {months}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    rules={[{required: true, message: '日を選択してください'}]}
                    noStyle
                  >
                    <Select
                      placeholder="日を選択"
                      className="inputBox"
                      value={birthDay}
                      onChange={setBirthDay}
                    >
                      {days}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </label>
          </Form.Item>
        </div>

        <div className="form-item">
          <Form.Item
            name="postalCode"
            rules={[{required: true, message: '入力してください'}, {
              pattern: /^[0-9]{7}$/, message: '7桁の数字を入力してください',
            }]}
          >
            <label><span className="required">*</span>郵便番号(半角数字·ハイフンなし)
              <a href="https://www.post.japanpost.jp/zipcode/"
                 target="_blank" rel="noreferrer noopener"
                 style={{fontSize: '14px', textDecoration: 'underline'}}>郵便番号がわからない場合はこちら</a>
              <Input
                className="inputBox"
                type="tel"
                maxLength={7}
                value={postalCode}
                onChange={handlePostalCodeChange}
                placeholder="例）1640012"
              />
            </label>

          </Form.Item>
        </div>

        <div className="form-item">
          <Form.Item rules={[{required: true, message: '郵便番号からを入力してください'}]}>
            <label><span className="required">*</span>都道府県市区住所
              <p style={{fontSize: '14px'}}>※郵便番号から自動入力</p>
              <Input className="inputBox" value={addressBase} readOnly
                     onChange={(e) => setAddressBase(e.target.value)}
                     placeholder="例）東京都中野区本町" />
            </label>
          </Form.Item>
        </div>

        <div className="form-item">
          <Form.Item rules={[{required: true, message: '詳細住所を入力してください'}]}>
            <label><span className="required">*</span>上記以降の住所(半角数字記号と全角文字)
              <p style={{fontSize: '14px'}}>※アパート、マンション名、部屋番号も入カしてください</p>
              <Input className="inputBox" value={specificAddress} onChange={(e) => {
                const value = e.target.value;
                const filteredValue = value
                  .replace(/[！-～]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0)) // 全角字符转换为半角字符
                  .replace(/[０-９ａ-ｚＡ-Ｚ]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
                  .replace(/。/g, '.') // 全角句号转换为半角句号
                  .replace(/．/g, '.') // 全角句号转换为半角句号
                  .replace(/、/g, ',') // 全角逗号转换为半角逗号
                  .replace(/－/g, '-') // 全角减号转换为半角减号
                  .replace(/\u3000/g, ' '); // 全角空格转换为半角空格
                setSpecificAddress(filteredValue);
              }}
                // onChange={(e) => setSpecificAddress(e.target.value)}
                     placeholder="例）2-46-1 中野坂上サンブライトツイン　10階" />
            </label>
          </Form.Item>
        </div>

        <div className="form-item">
          <Form.Item name="emergencyContactName" rules={[{required: true, message: '入力してください'}]}>
            <label><span className="required">*</span>緊急連絡先名
              <Input className="inputBox" value={emergencyContactName} onChange={validateEmergencyContactName} />
            </label>
          </Form.Item>
        </div>

        <div className="form-item">
          <Form.Item name="emergencyContactPhoneNumber" validateTrigger="onBlur" rules={[{
            required: true, message: '地域を選択してから電話を入力してください',
          }, {
            pattern: new RegExp(/^(0\d{2}-\d{4}-\d{4})$/), message: '有効な電話番号を入力してください',
          }, {
            validator: () => checkPhoneNumbers('phoneNumber', phoneNumber),
          }]}>
            <label><span className="required">*</span>緊急連絡先電話
              <PhoneInput
                defaultCountry="JP"
                placeholder="地域を選択してから電話を入力してください"
                className="custom-phone-input"
                maxLength={13}
                value={emergencyContactPhoneNumber}
                onChange={handleEmergencyContactPhoneNumberChange}
              />
            </label>
          </Form.Item>
        </div>

        <div className="form-item">
          <Form.Item name="emergencyContactRelationship"
                     rules={[{required: true, message: '入力してください'}]}>
            <label><span className="required">*</span>緊急連絡先関係
              <Input className="inputBox" placeholder="例）配偶者/友達/父/母など" />
            </label>
          </Form.Item>
        </div>

        <div className="form-item">
          <Form.Item name="accountHolderName" rules={[{required: true, message: '入力してください'}]}
                     label={<span>口座名義</span>}>
            <Select onChange={handleBankChange} className="inputBox" placeholder="例）本人名義">
              <Option value="本人名義">本人名義</Option>
              <Option value="法人名義">法人名義</Option>
              <Option value="家人名義">家人名義</Option>
            </Select>
          </Form.Item>
        </div>

        <div className="form-item">
          <Form.Item name="financialInstitutionName" rules={[{required: true, message: '入力してください'}]}>
            <label><span className="required">*</span>金融機関
              <Input
                id="searchBank"
                className="inputBox"
                placeholder="金融機関名を検索する"
                value={bankName}
                onChange={handleBankNameInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              {isSuggestedOpen ? (<>
                <div style={{color: 'red'}}>以下の金融機関から選んでください</div>
                <ul className="bank-name-suggested"
                    style={{border: '1px solid #1677ff'}}>{bankNameMessage()}</ul>
              </>) : ('')}
            </label>
          </Form.Item>
        </div>

        <div className="form-item">
          <Form.Item name="branchCode" rules={[{required: true, message: '入力してください'}]}>
            <label><span className="required">*</span>支店コード(半角数字)
              <Input
                id="inputBranchCode"
                className="inputBox"
                placeholder="例）000"
                type="text"
                value={branchCode}
                onChange={handleBranchCodeChange}
              />
            </label>
          </Form.Item>
        </div>

        <div className="form-item">
          <Form.Item name="branchName" rules={[{required: true, message: '入力してください'}]}>
            <label><span className="required">*</span>支店名
              <Input
                id="inputBranchName"
                name="branchName"
                className="inputBox"
                placeholder="例）丸の内中央"
                type="text"
                value={branchName}
                onChange={handleBranchNameChange}
              />
            </label>
          </Form.Item>
        </div>

        <div className="form-item">
          <Form.Item
            name="accountNumber"
            rules={[{required: true, message: '入力してください'}, {
              pattern: /^[0-9]{7}$/, message: '7桁の数字を入力してください',
            }]}
          >
            <label>
              <span className="required">*</span>口座番号(半角数字)
              <Input
                className="inputBox"
                type="text"
                inputMode="numeric"
                maxLength={7}
                onKeyPress={(e) => {
                  if (!/^[0-9]$/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!/^[0-9]*$/.test(value)) {
                    e.preventDefault();
                  }
                }}
                placeholder="7桁未満の口座番号先頭に「0」を付け、7桁より多いは最後の1桁を省略してください。"
              />
            </label>
          </Form.Item>
        </div>

        <div className="form-item">
          <Notice />
        </div>

        <div className="form-item">
          <Form.Item name="idPhoto" valuePropName="fileList" getValueFromEvent={idPhoto}
                     rules={[{required: true, message: 'アップロードしてください'}]}
                     label={<span>顔写真</span>}
          >
            <Upload
              name="SW&SqlUpload"
              listType="picture"
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
            >
              <Button type="primary">アップロード</Button>
            </Upload>
          </Form.Item>
          {loading && <Spin tip="顔写真認証中..." />} {/* 显示加载提示 */}
        </div>

        {!idUploadState && (<div className="form-item">
          <Form.Item name="selfIdentificationDocumentImage" valuePropName="fileList"
                     getValueFromEvent={idImgFirst('zaiLiuKa1')}
                     rules={[{required: true, message: 'アップロードしてください'}]}
                     label={<span>在留カードの表面写真</span>}
          >
            <Upload
              name="SW&SqlUpload"
              listType="picture"
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
            >
              <Button type="primary">アップロード</Button>
            </Upload>
          </Form.Item>
          {loading && <Spin tip="在留カード認証中..." />} {/* 显示加载提示 */}
        </div>)}

        {!idUploadState && (<div className="form-item">
          <Form.Item name="selfIdentificationDocumentImageSec" valuePropName="fileList"
                     getValueFromEvent={idImgSec('zaiLiuKa2')}
                     rules={[{required: true, message: 'アップロードしてください'}]}
                     label={<span>在留カードの裏面写真</span>}
          >
            <Upload
              name="SW&SqlUpload"
              listType="picture"
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
            >
              <Button type="primary">アップロード</Button>
            </Upload>
          </Form.Item>
          {loading && <Spin tip="在留カード認証中..." />} {/* 显示加载提示 */}
        </div>)}

        {idUploadState && (<div className="form-item">
          <Form.Item name="imgtype">
            <label>身分証明書を選択してください</label>
            <Select defaultValue="driversLicense" style={{width: 300}}
                    options={[{value: 'driversLicense', label: '運転免許証表面＋裏面'}, {
                      value: 'myNumber', label: '保険証表面＋個人番号カード表面',
                    }, {value: 'hoken', label: '保険証表面＋住民票'}]} />
          </Form.Item>
        </div>)}

        {idUploadState && (<div className="form-item">
          <Form.Item name="selfIdentificationDocumentImage" valuePropName="fileList"
                     getValueFromEvent={idImgFirst('shuLei1')}
                     rules={[{required: true, message: 'アップロードしてください'}]}
                     label={<span>身分証明書1をアップロードしてください</span>}
          >
            <Upload
              name="SW&SqlUpload"
              listType="picture"
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
            >
              <Button type="primary">アップロード</Button>
            </Upload>
          </Form.Item>
          {loading && <Spin tip="書類認証中..." />} {/* 显示加载提示 */}
        </div>)}

        {idUploadState && (<div className="form-item">
          <Form.Item name="selfIdentificationDocumentImageSec" valuePropName="fileList"
                     getValueFromEvent={idImgSec('shuLei2')}
                     rules={[{required: true, message: 'アップロードしてください'}]}
                     label={<span>身分証明書2をアップロードしてください</span>}
          >
            <Upload
              name="SW&SqlUpload"
              listType="picture"
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
            >
              <Button type="primary">アップロード</Button>
            </Upload>
          </Form.Item>
          {loading && <Spin tip="書類認証中..." />} {/* 显示加载提示 */}
        </div>)}

        <div className="form-item">
          <Form.Item name="bankDocumentImageSec" valuePropName="fileList" getValueFromEvent={bankImgSec}
                     rules={[{required: true, message: 'アップロードしてください'}]}
                     label={<span>銀行カードまたは通帳の写真</span>}
          >
            <Upload
              name="SW&SqlUpload"
              listType="picture"
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
            >
              <Button type="primary">アップロード</Button>
            </Upload>
          </Form.Item>
          {loading && <Spin tip="銀行口座認証中..." />} {/* 显示加载提示 */}
        </div>

        {bankUploadState && (<div className="form-item">
          <Form.Item name="familyselfIdentificationDocumentImageSec" valuePropName="fileList"
                     getValueFromEvent={familyImgSec}
                     rules={[{required: true, message: 'アップロードしてください'}]}
                     label={<span>本人および名義人が記載された住民票（家人名義銀行口座のみ）</span>}
          >
            <Upload
              name="SW&SqlUpload"
              listType="picture"
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
            >
              <Button type="primary">アップロード</Button>
            </Upload>
          </Form.Item>
          {loading && <Spin tip="住民票認証中..." />} {/* 显示加载提示 */}
        </div>)}

        {!idUploadState && japaneseSurname && (<div className="form-item">
          <Form.Item name="japNameselfIdentificationDocumentImageSec" valuePropName="fileList"
                     getValueFromEvent={japNameImgSec}
                     rules={[{required: true, message: 'アップロードしてください'}]}
                     label={<span>日本語通称姓名記載される証明書<Tooltip
                       title={<>
                         <p>日本語通称姓名証明書は以下の証明書を参考にしてください<br />運転免許証,マイナンバー,住民票のいずれか
                         </p>
                         <img src="https://sign.jtmh.jp/PDFs/license1.png" alt="運転免許証表"
                              style={{width: '285px'}} />
                         <img src="https://sign.jtmh.jp/PDFs/license2.png" alt="運転免許証裏"
                              style={{width: '285px'}} />
                         <img src="https://sign.jtmh.jp/PDFs/myNumber.png" alt="マイナンバーカード"
                              style={{width: '285px'}} />
                         {/*<img src="path/to/residence_card_image.jpg" alt="住民票" style={{width: '333px'}} />*/}
                       </>}
                       placement="right"
                       overlayInnerStyle={{width: '300px'}}>
              <InfoCircleOutlined style={{fontSize: '16px', marginLeft: '5px'}} />
            </Tooltip></span>}
          >
            <Upload
              name="SW&SqlUpload"
              listType="picture"
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
            >
              <Button type="primary">アップロード</Button>
            </Upload>
          </Form.Item>
          {loading && <Spin tip="証明認証中..." />} {/* 显示加载提示 */}
        </div>)}

        <div className="pdf-links">
          <div><a href="https://sign.jtmh.jp/PDFs/DMJP-ガイト用業務委託契約書.pdf"
                  target="_blank" rel="noreferrer noopener">DMJP-ガイト用業務委託契約書.pdf</a></div>
          <div><a href="https://sign.jtmh.jp/PDFs/DMJP-個人情報の取扱いに関する覚書.pdf"
                  target="_blank" rel="noreferrer noopener">DMJP-個人情報の取扱いに関する覚書.pdf</a></div>
          <div><a href="https://sign.jtmh.jp/PDFs/KMM-ガイト用業務委託契約書.pdf"
                  target="_blank" rel="noreferrer noopener">KMM-ガイト用業務委託契約書.pdf</a></div>
          <div><a href="https://sign.jtmh.jp/PDFs/KMM-個人情報の取扱いに関する覚書.pdf"
                  target="_blank" rel="noreferrer noopener">KMM-個人情報の取扱いに関する覚書.pdf</a></div>

          <h2>※署名(重要/姓名フルネームを手書きしてください、漢字またはローマ字のいずれでもかまいません。)</h2>
          <div className="signature-section">
            <Button className="clear-button" type="primary" danger ghost
                    onClick={handleClearSignature} style={{
              width: '100%', borderRadius: '5px', height: '40px', fontSize: '16px',
            }}>修正</Button>
            <div name="signBoxRef" className="signature-box" ref={signBoxRef}>
              <SignatureCanvas
                ref={signatureRef}
                penColor="black"
                canvasProps={{width: signBoxWidth, height: 200, className: 'signature-canvas'}}
              />
            </div>
          </div>
        </div>

        <Form.Item>
          <div style={{textAlign: 'center'}}>
            <Button className="submit-button" type="primary" htmlType="submit" style={{
              width: '100%', borderRadius: '5px', height: '40px', fontSize: '16px',
            }}>確認画面へ</Button>
          </div>
        </Form.Item>
      </Form>
      <>
        <Modal
          // title="確認画面"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={800}
          footer={[<Button key="edit" onClick={handleEdit}>
            修正
          </Button>, <Button key="submit" type="primary" onClick={handleOk}>
            確認して提出
          </Button>]}
        >
          {modalData && (<div className="confirmation-page">
            <Card className="confirmation-card">
              <h2 className="confirmation-title">JTMHガイド契約情報確認</h2>
              <Descriptions bordered column={1} className="confirmation-descriptions">
                {Object.entries(modalData.userData).map(([key, value]) => (
                  <Descriptions.Item label={fieldLabels[key] || key} key={key}>
                    {key === 'gender' ? genderMapping[value] : value}
                  </Descriptions.Item>))}
              </Descriptions>
            </Card>
          </div>)}
        </Modal>
      </>
    </div>

  );
};

export default UserForm;
